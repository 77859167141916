<template>
  <div class="home-page">
    <div class="main-content">
      <div style="width: 48%">
        <div class="content-title" style="padding-bottom: 5px">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="display: inline-block; position: relative; max-width: 100%"
            >
              <div
                style="
                  text-align: left;
                  font-family: 微软雅黑;
                  font-size: 22px;
                  font-weight: normal;
                  font-style: normal;
                  color: #bd1a2d;
                "
              >
                新闻中心
              </div>
            </div>
            <div>
              <div
                style="
                  max-width: 100%;
                  text-align: center;
                  font-family: 微软雅黑;
                  font-size: 15px;
                  color: #999999;
                  cursor: pointer;
                "
                @click="goRouter({ value: '4', url: '/newscenter' })"
              >
                更多 >
              </div>
            </div>
          </div>
          <span class="line-abs"></span>
        </div>
        <section class="news-content">
          <div class="news-imges" style="width: 49%">
            <el-carousel indicator-position="none" height="220px">
              <el-carousel-item
                v-for="(item, index) in importNewslist"
                :key="index"
              >
                <div
                  style="
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    flex-flow: column;
                  "
                >
                  <img
                    :src="item.thumbnail"
                    alt=""
                    style="width: 100%; height: calc(100% - 40px)"
                    @click="goToDetail(item.newsId)"
                  />
                  <div class="news-title">{{ item.newsTitle }}</div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="news-list">
            <div
              class="news-item"
              v-for="(item, index) in importNewslist"
              :key="index"
              @click="goToDetail(item.newsId)"
            >
              <div class="news-title">{{ item.newsTitle }}</div>
              <div class="news-time">{{ item.timeType }}</div>
            </div>
          </div>
        </section>
      </div>
      
      <div style="width: 22%">
        <div class="content-title" style="padding-bottom: 5px">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="display: inline-block; position: relative; max-width: 100%"
            >
              <div
                style="
                  text-align: left;
                  font-family: 微软雅黑;
                  font-size: 22px;
                  font-weight: normal;
                  font-style: normal;
                  color: #bd1a2d;
                "
              >
                信息公告
              </div>
            </div>
            <div>
              <div
                style="
                  max-width: 100%;
                  text-align: center;
                  font-family: 微软雅黑;
                  font-size: 15px;
                  color: #999999;
                  cursor: pointer;
                "
                @click="goRouter({ value: '2', url: '/newscenter?type=2' })"
              >
                更多 >
              </div>
            </div>
          </div>
          <span class="line-abs"></span>
        </div>
        <!-- <section class="notice-news" style="overflow: hidden">
          <div class="news-list">
            <div
              class="news-item"
              v-for="(item, index) in noticeNewsList"
              :key="index"
              style=" cursor: pointer;"
              @click="goToDetail(item.newsId)"
            >
              <div class="news-title">{{ item.newsTitle }}</div>
              <div class="news-time">{{ item.timeType }}</div>
            </div>
          </div>
        </section> -->
        <section class="news-content">
          <div class="news-list" style="width: 100%;">
            <div
              class="news-item"
              v-for="(item, index) in infoNewsList"
              :key="index"
              @click="goToDetail(item.newsId)"
            >
              <div class="news-title">{{ item.newsTitle }}</div>
              <div class="news-time" style="width: 24%;">{{ item.timeType }}</div>
            </div>
          </div>
        </section>
      </div>
      <div style="width: 22%">
        <div class="content-title" style="padding-bottom: 5px">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="display: inline-block; position: relative; max-width: 100%"
            >
              <div
                style="
                  text-align: left;
                  font-family: 微软雅黑;
                  font-size: 22px;
                  font-weight: normal;
                  font-style: normal;
                  color: #bd1a2d;
                "
              >
                招租公告
              </div>
            </div>
            <div>
              <div
                style="
                  max-width: 100%;
                  text-align: center;
                  font-family: 微软雅黑;
                  font-size: 15px;
                  color: #999999;
                  cursor: pointer;
                "
                @click="goRouter({ value: '5', url: '/newscenter?type=5' })"
              >
                更多 >
              </div>
            </div>
          </div>
          <span class="line-abs"></span>
        </div>
        <!-- <section class="notice-news" style="overflow: hidden">
          <div class="news-list">
            <div
              class="news-item"
              v-for="(item, index) in noticeNewsList"
              :key="index"
              style=" cursor: pointer;"
              @click="goToDetail(item.newsId)"
            >
              <div class="news-title">{{ item.newsTitle }}</div>
              <div class="news-time">{{ item.timeType }}</div>
            </div>
          </div>
        </section> -->
        <section class="news-content">
          <div class="news-list" style="width: 100%;">
            <div
              class="news-item"
              v-for="(item, index) in noticeNewsList"
              :key="index"
              @click="goToDetail(item.newsId)"
            >
              <div class="news-title">{{ item.newsTitle }}</div>
              <div class="news-time" style="width: 24%;">{{ item.timeType }}</div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="main-content">
      <div style="width: 100%">
        <div class="content-title" style="padding-bottom: 5px">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="display: inline-block; position: relative; max-width: 100%"
            >
              <div
                style="
                  text-align: left;
                  font-family: 微软雅黑;
                  font-size: 22px;
                  font-weight: normal;
                  font-style: normal;
                  color: #bd1a2d;
                "
              >
                集团产业
              </div>
            </div>
            <div>
              <div
                style="
                  max-width: 100%;
                  text-align: center;
                  font-family: 微软雅黑;
                  font-size: 15px;
                  color: #999999;
                  cursor: pointer;
                "
                @click="goRouter({ value: '3', url: '/partyconstruction' })"
              >
                更多 >
              </div>
            </div>
          </div>
          <span class="line-abs"></span>
        </div>
        <section style="overflow: hidden">
          <div class="group-industry">
            <div class="company-type-card" @click="goMenu(1)">
              <img src="@/assets/index/ggfw.jpg" alt="" />
              <div class="company-type-title">公用事业</div>
            </div>
            <div class="company-type-card" @click="goMenu(2)">
              <img src="@/assets/index/szjsgc.jpg" alt="" />
              <div class="company-type-title">市政工程</div>
            </div>
            <div class="company-type-card" @click="goMenu(3)">
              <img src="@/assets/index/csjsgh.png" alt="" />
              <div class="company-type-title">商业运营</div>
            </div>
            <div class="company-type-card" @click="goMenu(4)">
              <img src="@/assets/index/cytz.png" alt="" />
              <div class="company-type-title">产业投资</div>
            </div>
            <!-- <div class="company-type-card" @click="goMenu(5)">
            <img src="@/assets/index/yllh.jpg" alt="" />
            <div class="company-type-title">人才发展</div>
          </div> -->
          </div>
        </section>
      </div>
    </div>
    <div class="float-menu" v-drag>
      <img src="@/assets/index/float.png" alt="" />
    </div>
  </div>
</template>

<script>
import eventBus from "../../../js/eventBus";
import { getNewsNoticeAll, getCommonInfo } from "../../../js/api/index";
export default {
  data() {
    return {
      BASE_URL: BASE_URL,
      num: 0,
      timer: null,
      timer2: null,
      box: "",
      newsType: 1, //新闻类型选择
      importNewslist: [],
      noticeNewsList: [],
      infoNewsList: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 6,
        type: '1,4,6,7,syyy,cytz,rcfz,szgc', //默认写死  先不放全部
      },
    };
  },
  methods: {
    //元素超过4个之后才开始新建定时器实现滚动
    init() {
      // if (this.dataList.length > 4) {
      this.box = this.$refs.wrapper;
      this.timer = setInterval(() => {
        this.move();
      }, 10);
      // }
    },
    // 跑马灯工作
    move() {
      let curLeft = this.box.scrollLeft;
      //父盒子总宽度除以2 (20是我这边盒子之间的右边距)
      let scrollWidth = this.$refs.marquee.scrollWidth / 2 + 20;
      this.box.scrollLeft = curLeft + 1;
      if (curLeft > scrollWidth) {
        this.box.scrollLeft = 0;
      }
    },
    //鼠标悬停
    mouseover() {
      clearInterval(this.timer);
      this.timer = null;
    },
    //鼠标离开，继续滚动
    mouseout() {
      this.init();
    },
    changeNewsType(type) {
      this.newsType = type;
      this.pageInfo.type = type;
      this.initNewsData();
    },
    splitTime(time) {
      // 分割日期和时间
      var splitDateTime = time.split(" ");
      var date = splitDateTime[0];
      var time = splitDateTime[1];
      // 分割年、月、日
      var splitDate = date.split("-");
      var year = splitDate[0];
      var month = splitDate[1];
      var day = splitDate[2];
      return date;
    },
    //初始化信息
    initNewsData() {
      let that = this;
      getNewsNoticeAll(this.pageInfo).then((res) => {
        let list = res.data.list;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          element.timeType = that.splitTime(element.createTime);
        }
        this.importNewslist = list;
        console.log(list);
      });
    },
    //通知公告滚动条-改为招租公告  type2-》5
    initNoticeData() {
      let data = {
        pageNum: 1,
        pageSize: 6,
        type: 5,
      };
      let data2 = {
        pageNum: 1,
        pageSize: 6,
        type: 2,
      };
      let that = this;
      getNewsNoticeAll(data).then((res) => {
        let list = res.data.list;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          element.timeType = that.splitTime(element.createTime);
        }
        this.noticeNewsList = list;
      });
      getNewsNoticeAll(data2).then((res) => {
        let list = res.data.list;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          element.timeType = that.splitTime(element.createTime);
        }
        this.infoNewsList = list;
      });
    },
    goRouter(data) {
      this.activeIndex = data.url;
      let routeParams = this.$route.query.type;
      if (this.$route.path !== data.url || routeParams) {
        this.$router.push({ path: data.url }); // 跳转并传递参数
      }
    },
    //路由跳转新闻详情页
    // goToDetail(id){
    //   eventBus.emit('currentChange', 3);
    //   this.$router.push({name:'newsdetail',params: {id}}) // 只能用 name
    // },
    goToDetail(id) {
      this.$router.push({ path: "/newsdetail", query: { id: id } }); // 跳转并传递参数
    },
    //跳转新页面
    handleClick() {
      window.open("http://58.223.177.10:13000/portal", "_blank");
    },
    goMenu(value) {
      // query: { label: label, id: id ,code:code},
      if (value == 1) {
        this.$router.push({
          path: "/partyconstruction",
          query: { label: '公用事业', id: '1' ,code:'gysy'},
          // query: { type: "1-gysy" },
        }); // 跳转并传递参数
      } else if (value == 2) {
        this.$router.push({
          path: "/partyconstruction",
          query: { label: '市政工程', id: '2' ,code:'szgc'},
          // query: { type: "2-szgc" },
        }); // 跳转并传递参数
      } else if (value == 3) {
        this.$router.push({
          path: "/partyconstruction",
          query: { label: '商业运营', id: '3' ,code:'syyy'},
          // query: { type: "3-syyy" },
        }); // 跳转并传递参数
      } else if (value == 4) {
        this.$router.push({
          path: "/partyconstruction",
          // query: { type: "4-cytz" },
          query: { label: '产业投资', id: '4' ,code:'cytz'},
        }); // 跳转并传递参数
      } else if (value == 5) {
        this.$router.push({
          path: "/partyconstruction",
          // query: { type: "5-rcfz" },
          query: { label: '人才发展', id: '5' ,code:'rcfz'},
        }); // 跳转并传递参数
      }
    },
  },
  created() {
    this.initNewsData();
    this.initNoticeData();
  },
  mounted() {
    // this.init();
  },
  //销毁定时器
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.float-menu {
  position: fixed;
  bottom: 15%;
  right: 3%;
  cursor: pointer;
  width: 6vw;
  z-index: 100001;
  img {
    width: 100%;
  }
}
.line-abs {
  right: auto;
  display: block;
  top: 0;
  position: relative;
  overflow: hidden;
  border-bottom-style: solid;
  width: 98%;
  border-bottom-width: 1px;
  border-bottom-color: #e2e2e2;
  overflow: inherit;
  margin-top: 8px;
  margin-left: 0;
}
.line-abs::before {
  position: absolute;
  content: "";
  width: 86px;
  height: 2px;
  background-color: #bd1a2d;
  left: 0;
  top: 0px;
  margin: 0;
}
.news-imges {
  width: 49%;
  .news-title {
    color: #ffffff;
    font-size: 15px;
    font-family: 微软雅黑;
    font-weight: normal;
    height: 40px;
    padding-left: 4px;
    padding-right: 4px;
    line-height: 40px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: rgba(68, 68, 68, 1);
  }
}

.news-content {
  overflow: hidden;
  height: 220px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  .news-list {
    width: 49%;
    .news-item {
      border-bottom: 1px dashed rgba(224, 224, 224, 1);
      display: flex;
      justify-content: space-between;
      padding: 10px 0 15px;
      cursor: pointer;
    }
    .news-title {
      color: #666666;
      font-size: 14px;
      font-family: 微软雅黑;
      line-height: 20px;
      font-weight: normal;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70%;
      text-align: left;
    }
    .news-time {
      color: #aaaaaa;
      font-size: 12px;
    }
  }
}

.group-industry {
  margin-top: 18px;
  // margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .company-type-card {
    width: 24%;
    margin-bottom: 2px;
    cursor: pointer;
    .company-type-title {
      // font-weight: bold;
      font-size: 20px;
      margin-bottom: 2px;
      color: black;
      text-align: center;
    }
    > img {
      width: 100%;
      height: 225px;
    }
  }
}

.notice-news {
  .news-list {
    width: 100%;
    .news-item {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      padding: 10px 0 15px;
      position: relative;
    }
    .news-title {
      color: #666666;
      font-size: 14px;
      font-family: 微软雅黑;
      line-height: 20px;
      font-weight: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 3; /* 设置最大显示行数为2行 */
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
    }
    .news-time {
      color: #aaaaaa;
      font-size: 12px;
      text-align: right;
    }
  }
}
</style>